import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router.js'
import store from './store'
import '@/assets/css/elementStyle.css'		//修改element样式
import '@/assets/css/globalStyle.css'		//全局样式

import ElementUI  from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import {
	Message
} from 'element-ui'


Vue.use(ElementUI);



let loading = null
let requestCount = 0
// 显示loading
function showLoading() {
	if (requestCount === 0) {
		loading = Message({
			message: '加载中...',
			duration: 0
		});
	}
	requestCount++
}
// 隐藏loading
function hideLoading() {
	if (requestCount > 0) {
		requestCount--
	}
	if (loading && requestCount === 0) {
		loading.close()
	}
}


axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 添加请求拦截器
axios.interceptors.request.use((config) => {
	// 添加header头的token
	let token = window.sessionStorage.getItem('token')
	if (config.token === true) {
		config.headers['token'] = token
	}
	// 显示loading
	if (config.loading === true) {
		showLoading()
	}
	// 在发送请求之前做些什么
	return config;
}, (error) => {
	// 隐藏loading
	hideLoading()
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
	if(response.data.code == -1){
		// 登录过期，重新登录
		Message.error(response.data.msg)
		// 清除状态和存储
		store.commit('logout')
		// 返回到登录页
		router.push({
			name: "passport/login"
		})
	}

	// 隐藏loading
	hideLoading()
	// 对响应数据做点什么
	return response;
}, (err) => {

});

// 引入全局配置文件
import $conf from "./common/config/config.js"
Vue.prototype.$conf = $conf


// 自定义指令
Vue.directive('auth', {
	inserted(el, binding, vnode, oldVnode) {
		let user = window.sessionStorage.getItem('user')
		user = user ? JSON.parse(user) : {}
		if (!user.user.is_super) {
			// let rules = user.ruleNames ? user.ruleNames : []

			// let v = rules.find(item => item === binding.value)
			// if (!v) {
			// 	el.parentNode.removeChild(el)
			// }
		}
	}
})

//引入图片查看器
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

// 引入全局选择组件
import appPickLink from '@/components/app-pick-link.vue';
import appAttachment from '@/components/app-attachment.vue';
import appImage from '@/components/app-image.vue';
import appGallery from '@/components/app-gallery.vue';
import appUpload from '@/components/app-upload.vue';
import appMap from '@/components/app-map.vue';
Vue.component('appPickLink', appPickLink);
Vue.component('appAttachment', appAttachment);
Vue.component('appImage', appImage);
Vue.component('appGallery', appGallery);
Vue.component('appUpload', appUpload);
Vue.component('appMap', appMap);



Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
