/**
 * 规则：
 * 一、例如：index/index，shop/index以index结尾的，path和name默认去除index
 * 二、例如：shop/list，默认生成name为shop_list（如果结尾为index，例如shop/index则是shop）
 * 三、填写后不会自动生成
 * **/
let routes = [{
		path: '/',
		name: 'layout',
		param: false,
		redirect: {
			name: 'statistics/index'
		},
		component: 'layout',
		children: [{
			meta: {
				title: '首页'
			},
			component: 'index/index',
			param: false
		}, {
			meta: {
				title: '信件管理'
			},
			component: 'letter/index',
			param: false
		}, {
			meta: {
				title: '添加信件'
			},
			component: 'letter/add',
			param: false
		}, {
			meta: {
				title: '留言管理'
			},
			component: 'letter.leave/index',
			param: false
		}, {
			meta: {
				title: '用户管理'
			},
			component: 'user/index',
			param: false
		}, {
			meta: {
				title: '单位用户'
			},
			component: 'unit_user/index',
			param: false
		}, {
			meta: {
				title: '单位用户编辑'
			},
			component: 'unit_user/edit',
			param: false
		}, {
			meta: {
				title: '网格用户'
			},
			component: 'unit_user.reseau/index',
			param: false
		}, {
			meta: {
				title: '添加网格用户'
			},
			component: 'unit_user.reseau/add',
			param: false
		}, {
			meta: {
				title: '网格更改'
			},
			component: 'unit_user.reseau/edit',
			param: false
		}, {
			meta: {
				title: '单位更改日志'
			},
			component: 'unit_user/editlog',
			param: false
		}, {
			meta: {
				title: '网格更改日志'
			},
			component: 'unit_user.reseau/editlog',
			param: false
		}, {
			meta: {
				title: '监管统计'
			},
			component: 'statistics/index',
			param: false
		}, {
			meta: {
				title: '举报统计'
			},
			component: 'statistics/report',
			param: false
		}, {
			meta: {
				title: '文章列表'
			},
			component: 'content.article/index',
			param: false
		}, {
			meta: {
				title: '文章分类'
			},
			component: 'content.article.category/index',
			param: false
		}, {
			meta: {
				title: '轮播图'
			},
			component: 'content.banner/index',
			param: false
		}, {
			meta: {
				title: '素材管理'
			},
			component: 'content.material/index',
			param: false
		}, {
			meta: {
				title: '行动管理'
			},
			component: 'action/index',
			param: false
		}, {
			meta: {
				title: '行动添加'
			},
			component: 'action/add',
			param: false
		}, {
			meta: {
				title: '行动编辑'
			},
			component: 'action/edit',
			param: 'action_id'
		}, {
			meta: {
				title: '单位管理'
			},
			component: 'unit/index',
			param: false
		}, {
			meta: {
				title: '网格管理'
			},
			component: 'unit.reseau/index',
			param: false
		}, {
			meta: {
				title: '网格添加'
			},
			component: 'unit.reseau/add',
			param: false
		}, {
			meta: {
				title: '网格批量添加'
			},
			component: 'unit.reseau/batchAdd',
			param: false
		}, {
			meta: {
				title: '大队管理'
			},
			component: 'unit.brigade.user/index',
			param: false
		}, {
			meta: {
				title: '单位添加'
			},
			component: 'unit/add',
			param: false
		}, {
			meta: {
				title: '单位编辑'
			},
			component: 'unit/edit',
			param: 'unit_id'
		}, {
			meta: {
				title: '用户添加'
			},
			component: 'unit.brigade.user/add',
			param: false,
		}, {
			meta: {
				title: '用户编辑'
			},
			component: 'unit.brigade.user/edit',
			param: 'brigade_user_id',
		}, {
			meta: {
				title: '排查登记'
			},
			component: 'reseau.check/index',
			param: false,
		}, {
			meta: {
				title: '排查统计'
			},
			component: 'reseau.check/check_statistics',
			param: false,
		}, {
			meta: {
				title: '排查编辑'
			},
			component: 'reseau.check/edit',
			param: 'reseau_check_id',
		}, {
			meta: {
				title: '分布图'
			},
			component: 'reseau.check/map',
			param: false,
		}, {
			meta: {
				title: '网格整改分析'
			},
			component: 'reseau.check/statistics',
			param: false,
		}, {
			meta: {
				title: '群众举报'
			},
			component: 'report/index',
			param: false
		}, {
			meta: {
				title: '执法检查'
			},
			component: 'place.report/index',
			param: false
		}, {
			meta: {
				title: '小程序设置'
			},
			component: 'wxapp/setting',
			param: false
		}, {
			meta: {
				title: '管理员列表'
			},
			component: 'store.user/index',
			param: false
		}, {
			meta: {
				title: '管理员添加'
			},
			component: 'store.user/add',
			param: false
		}, {
			meta: {
				title: '管理员编辑'
			},
			component: 'store.user/edit',
			param: 'store_user_id'
		}, {
			meta: {
				title: '个人中心'
			},
			component: 'store.user/renew',
			param: false
		}, {
			meta: {
				title: '权限列表'
			},
			component: 'store.access/index',
			param: false
		}, {
			meta: {
				title: '角色列表'
			},
			component: 'store.role/index',
			param: ''
		}, {
			meta: {
				title: '角色添加'
			},
			component: 'store.role/add',
			param: false
		}, {
			meta: {
				title: '角色编辑'
			},
			component: 'store.role/edit',
			param: 'role_id'
		}, {
			meta: {
				title: '设置'
			},
			component: 'setting/setting',
			param: false
		}, {
			meta: {
				title: '404错误'
			},
			component: 'error/404',
			param: false
		}]
	},
	{
		meta: {
			title: '登录页'
		},
		component: 'passport/login',
		param: false
	},
	{
		path: '*',
		redirect: {
			name: 'statistics/index'
		},
	}
]

// 获取路由信息方法
let getRoutes = function() {
	// 自动生成路由
	createRoute(routes)
	console.log(routes)
	return routes
}

// 自动生成路由
function createRoute(arr) {
	for (let i = 0; i < arr.length; i++) {
		if (!arr[i].component) return
		// 去除index
		let val = arr[i].component.replace(/\./g, '/')
		// 生成name
		arr[i].name = arr[i].component
		if (arr[i].param) {
			// 生成path
			arr[i].path = '/' + val + '/:' + arr[i].param
		} else {
			// 生成path
			arr[i].path = '/' + val
		}

		// 自动生成component
		let componentFun = import(`../../views/${val}.vue`)
		arr[i].component = () => componentFun
		if (arr[i].children && arr[i].children.length > 0) {
			createRoute(arr[i].children)
		}
	}
}

export default getRoutes()
